import {
	addDays,
	addMonths,
	compareAsc,
	differenceInCalendarDays,
	endOfYear,
	format,
	getTime,
	isAfter,
	isBefore,
	isSameDay,
	lightFormat,
	startOfMonth,
	startOfWeek,
	startOfYear,
	subDays,
	subMonths,
} from 'date-fns'
import differenceInDays from 'date-fns/fp/differenceInDays'
import de from 'date-fns/locale/de'
import enGB from 'date-fns/locale/en-GB'
import it from 'date-fns/locale/it'
import { DateRange } from './../submodules/sharedTypes/common/DateRange'
import { Range } from './../submodules/sharedTypes/common/Range'
import { utilString } from './utilString'
import { DateGranularity, Emisphere } from '../submodules/sharedTypes/communication/common/StatsRequest'
import { formatInTimeZone, toDate } from 'date-fns-tz'

class UtilDate {
	startOfDate(date: Date): Date {
		const correctedDate = new Date(date)
		correctedDate.setHours(0)
		correctedDate.setMinutes(0)
		correctedDate.setSeconds(0)
		correctedDate.setMilliseconds(0)

		return correctedDate
	}

	toLocale(i18nLocale: string): Locale {
		switch (i18nLocale) {
			case 'de': {
				return de
			}
			case 'it': {
				return it
			}
		}
		return enGB
	}

	utcToCurrentTimezone(date: Date) {
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

		return toDate(date, { timeZone: timezone })
	}

	formatDateRange(range: Range<Date | undefined>) {
		return this.formatDate(range.from) + ' → ' + this.formatDate(range.to)
	}

	formatDateForEChart(date: Date) {
		return format(date, 'yyyy-MM-dd')
	}

	formatDate(date: Date | undefined) {
		return date == undefined
			? '--/--/----'
			: format(date, 'P', { locale: this.toLocale(useLocale().currentLocale.value) })
	}

	formatYear(date: Date | undefined) {
		return date == undefined ? '----' : format(date, 'yyyy')
	}

	formatExtendedDate(date: Date, locale: string) {
		const lowercaseString = format(date, 'EEEE do MMMM yyyy', {
			locale: this.toLocale(locale),
		})

		return lowercaseString
			.split(' ')
			.map((el) => el[0].toUpperCase() + el.slice(1))
			.join(' ')
	}

	formatHour(date: Date) {
		return format(date, 'p')
	}

	formatLocale(date: Date, locale: string) {
		return format(date, 'PPP', { locale: this.toLocale(locale) })
			.split(' ')
			.map((el) => utilString.capitalizeFirst(el))
			.join(' ')
	}

	formatShortLocale(date: Date, locale: string) {
		return format(date, 'PP', { locale: this.toLocale(locale) })
			.split(' ')
			.map((el) => utilString.capitalizeFirst(el))
			.join(' ')
	}

	formatShortestLocale(date: Date, locale: string) {
		return format(date, 'P', { locale: this.toLocale(locale) })
	}

	formatYearlessShortLocale(date: Date, locale: string) {
		return format(date, 'PP', { locale: this.toLocale(locale) })
			.split(' ')
			.map((el) => utilString.capitalizeFirst(el))
			.join(' ')
			.replace(date.getFullYear().toString(), '')
			.trim()
	}

	formatTextualDate(date: Date, locale: string) {
		const splittedDate = format(date, 'd MMMM yyyy', {
			locale: this.toLocale(locale),
		}).split(' ')

		return [splittedDate[0], utilString.capitalizeFirst(splittedDate[1]), splittedDate[2]].join(' ')
	}

	formatShortDate(date: Date) {
		return format(date, 'dd/MM')
	}

	formatShortDateWithoutYearLocale(date: Date, locale: string) {
		const formattedLocale = this.toLocale(locale)

		// Format day and month separately
		const day = format(date, 'dd', { locale: formattedLocale })
		const month = format(date, 'MM', { locale: formattedLocale })

		// Use the locale's date format to determine the separator
		const exampleDate = format(new Date(2020, 6, 4), 'P', { locale: formattedLocale })
		const separator = exampleDate.match(/[^\d]/)?.[0] || '/'

		// Combine day and month with the detected separator
		return `${day}${separator}${month}`
	}

	formatDayOnly(date: Date) {
		return format(date, 'dd')
	}

	getDayOfWeek(date: Date, locale: string) {
		let dayOfWeek = format(date, 'iii', {
			locale: this.toLocale(locale),
		})

		return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)
	}

	getCapitalDayOfWeek(date: Date, locale: string) {
		return this.getDayOfWeek(date, locale).toUpperCase()
	}

	getMonthYear(date: Date) {
		return format(date, 'MM/yy')
	}

	getDaysDifference(dates?: DateRange[]) {
		if (
			dates == undefined ||
			dates.length < 2 ||
			dates.some((date) => date?.from == undefined || date?.to == undefined)
		) {
			return 0
		}

		const dayCounts = dates.map((date) => differenceInCalendarDays(date.from, date.to))

		return Math.abs(dayCounts[0] - dayCounts[1])
	}

	daysDifference(dateRange?: DateRange) {
		if (dateRange == undefined) {
			return 0
		}
		return differenceInCalendarDays(dateRange.to, dateRange.from)
	}

	iterateDateRange(dateRange: Range<Date>, callback: (date: Date) => void) {
		let from = new Date(dateRange.from.getFullYear(), dateRange.from.getMonth(), dateRange.from.getDate())
		const to = new Date(dateRange.to.getFullYear(), dateRange.to.getMonth(), dateRange.to.getDate())

		while (!isSameDay(from, to)) {
			callback(from)
			from = addDays(from, 1)
		}
		callback(from)
	}

	iterateDateRangeWithFilter(dateRange: Range<Date>, filter: number[], callback: (date: Date) => void) {
		let from = new Date(dateRange.from.getFullYear(), dateRange.from.getMonth(), dateRange.from.getDate())
		const to = new Date(dateRange.to.getFullYear(), dateRange.to.getMonth(), dateRange.to.getDate())

		while (!isSameDay(from, to)) {
			if (filter.includes(from.getDay())) {
				callback(from)
			}
			from = addDays(from, 1)
		}
		if (filter.includes(from.getDay())) {
			callback(from)
		}
	}

	weekDaysInRange(dateRange: Range<Date>): number[] {
		const range: number[] = []
		this.iterateDateRange(dateRange, (date: Date) => {
			if (range.length < 7) {
				range.push(date.getDay())
			}
		})

		return range
	}

	weekDaysNamesInRange(locale: string, dateRange?: Range<Date>, filter: number[] = []): string[] {
		if (dateRange == undefined) {
			return []
		}

		const range: string[] = []
		this.iterateDateRangeWithFilter(dateRange, filter, (date: Date) => {
			if (range.length < 7) {
				range.push(this.getDayOfWeek(date, locale))
			}
		})

		return range
	}

	dateInRange(date: Date | undefined, range: DateRange) {
		return (
			date != undefined &&
			(isSameDay(range.from, date) ||
				isSameDay(range.to, date) ||
				(isBefore(date, range.to) && isAfter(date, range.from)))
		)
	}

	isSameDate(date: Date, secondDate: Date) {
		return isSameDay(date, secondDate)
	}

	sortDateArray(array: Date[]): Date[] {
		return array.sort((prev, next) => {
			return compareAsc(prev, next)
		})
	}

	sortDateRangeArray<T>(array: T[], keyToDateRange: keyof T): T[] {
		return array.sort((prev, next) => {
			const prevStart = (prev[keyToDateRange] as Range<Date>).from
			const nextStart = (next[keyToDateRange] as Range<Date>).from
			return compareAsc(prevStart, nextStart)
		})
	}

	compactToSingleDays<T>(array: T[], keyToDateRange: keyof T): Date[] {
		const allValues: Set<string> = new Set()

		this.sortDateRangeArray(array, keyToDateRange).forEach((el) => {
			const currentElement = el[keyToDateRange] as Range<Date>

			for (
				let dateToAdd = currentElement.from;
				!isSameDay(dateToAdd, currentElement.to);
				dateToAdd = addDays(dateToAdd, 1)
			) {
				allValues.add(lightFormat(dateToAdd, 'yyyy-MM-dd'))
			}
			allValues.add(lightFormat(currentElement.to, 'yyyy-MM-dd'))
		})

		return Array.from(allValues)
			.map((el) => new Date(el))
			.sort((d1, d2) => compareAsc(d1, d2))
	}

	toNonOverlappedArrays<T>(array: T[], keyToDateRange: keyof T): T[][] {
		const result: T[][] = []

		this.sortDateRangeArray(array, keyToDateRange).forEach((entry) => {
			const currentRange = entry[keyToDateRange] as Range<Date>
			let arrayLane = result.findIndex((lane: T[]) => {
				const lastRange = lane[lane.length - 1][keyToDateRange] as Range<Date>

				return isAfter(currentRange.from, lastRange.to)
			})

			if (arrayLane < 0) {
				arrayLane = result.length
				result.push([])
			}

			result[arrayLane].push(entry)
		})

		return result
	}

	isBeforeToday(date?: Date) {
		if (date == undefined) {
			return false
		}

		const today = new Date().setHours(0, 0, 0, 0)
		return isBefore(date, today)
	}

	isBefore(date: Date, otherDate: Date) {
		return isBefore(date, otherDate)
	}

	isToday(date?: Date) {
		const today = new Date().setHours(0, 0, 0, 0)
		return date != undefined && isSameDay(date, today)
	}

	isAfterToday(date?: Date) {
		if (date == undefined) {
			return false
		}

		const today = new Date().setHours(0, 0, 0, 0)
		return isAfter(date, today)
	}

	isAfter(baseDate: Date, comparedDate: Date) {
		return isAfter(baseDate, comparedDate)
	}

	getMonthName(idx: number) {
		var objDate = new Date()
		objDate.setDate(1)
		objDate.setMonth(idx - 1)

		var locale = useLocale().currentLocale.value,
			month = objDate.toLocaleString(locale, { month: 'long' })

		return month
	}

	getShortDayName(date: Date, locale: Locale = this.toLocale(useLocale().currentLocale.value)) {
		return format(date, 'EEE', { locale })
	}

	getMissingDaysOfTheWeek(dateRange: Range<Date> | undefined) {
		if (dateRange == undefined) {
			return []
		}
		const daysDifference = Math.abs(differenceInDays(dateRange.from, dateRange.to))
		if (daysDifference >= 7) {
			return []
		}

		const fromDay = dateRange.from.getDay()
		const toDay = dateRange.to.getDay()
		let result = []
		for (let i = (toDay + 1) % 7; i != fromDay; i = (i + 1) % 7) {
			result.push(i)
		}

		return result.sort()
	}

	dateRangeToDateArray(dateRange: DateRange) {
		const isSorted = isBefore(dateRange.from, dateRange.to)
		const startDate = isSorted ? dateRange.from : dateRange.to
		const endDate = isSorted ? dateRange.to : dateRange.from

		const result = []
		let counter = 0
		let currentDate = new Date(startDate)

		while (!isAfter(currentDate, endDate)) {
			result.push(currentDate)

			counter++
			currentDate = addDays(startDate, counter)
		}

		return result
	}

	addDays(date: Date, days: number): Date {
		return addDays(date, days)
	}

	// this is a wrapper, so that we can decide to swap time library in a simple way
	addMonths(date: Date, months: number): Date {
		return addMonths(date, months)
	}

	sameMomentNextYear(date: Date) {
		return new Date(
			date.getFullYear() + 1,
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds(),
			date.getMilliseconds()
		)
	}

	sameMomentLastYear(date: Date) {
		return new Date(
			date.getFullYear() - 1,
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds(),
			date.getMilliseconds()
		)
	}

	daysBetween(date1: Date, date2: Date) {
		return differenceInDays(date1, date2)
	}

	/**
	 * Returns an array of dates between the two dates, including the start and end dates
	 */
	getDatesBetween(startDate: Date, endDate: Date) {
		let dates = []
		let currentDate = new Date(startDate)

		while (currentDate <= endDate) {
			dates.push(new Date(currentDate))
			currentDate.setDate(currentDate.getDate() + 1)
		}

		return dates
	}

	/**
	 * Remove duplicates from an array of date ranges
	 */
	removeDuplicates(dates: Range<Date>[]) {
		const uniqueDateRanges = new Set<string>()
		return dates.filter((range) => {
			const rangeString = `${range.from.toISOString()}_${range.to.toISOString()}`
			if (uniqueDateRanges.has(rangeString)) {
				return false
			} else {
				uniqueDateRanges.add(rangeString)
				return true
			}
		})
	}

	/**
	 * Convert an array of dates to an array of date ranges
	 */
	fromDatesToPeriods(dates: Date[]): Range<Date>[] {
		return dates.reduce((periods, currentDate, index, dateList) => {
			if (index === 0) {
				// set the first periods
				periods.push({ from: currentDate, to: currentDate })
			} else {
				const previousDate = dateList[index - 1]
				const isConsecutive =
					this.daysBetween(currentDate, previousDate) === 1 ||
					this.daysBetween(currentDate, previousDate) === -1 ||
					this.daysBetween(currentDate, previousDate) === 0

				if (isConsecutive) {
					periods[periods.length - 1].to = currentDate
				} else {
					periods.push({ from: currentDate, to: currentDate })
				}
			}

			return periods
		}, [] as Range<Date>[])
	}

	getSeasonDates(year: number) {
		const springEquinox = addMonths(startOfYear(new Date(year)), 2) // Start with March 1st
		springEquinox.setHours(12) // Set time to 12:00 PM to account for time zones

		// Calculate the number of days between March 1st and the spring equinox
		const timeDiff = getTime(new Date(year, 2, 20, 12)) - getTime(springEquinox)
		const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

		// Add the number of days to March 1st to get the date of the spring equinox
		const springEquinoxDate = addDays(springEquinox, daysDiff)

		const summerSolstice = addMonths(startOfYear(new Date(year)), 5) // Start with June 1st
		summerSolstice.setHours(12) // Set time to 12:00 PM to account for time zones

		// Calculate the number of days between June 1st and the summer solstice
		const timeDiff2 = getTime(new Date(year, 5, 20, 12)) - getTime(summerSolstice)
		const daysDiff2 = Math.ceil(timeDiff2 / (1000 * 60 * 60 * 24))

		// Add the number of days to June 1st to get the date of the summer solstice
		const summerSolsticeDate = addDays(summerSolstice, daysDiff2)

		const fallEquinox = addMonths(startOfYear(new Date(year)), 8) // Start with September 1st
		fallEquinox.setHours(12) // Set time to 12:00 PM to account for time zones

		// Calculate the number of days between September 1st and the fall equinox
		const timeDiff3 = getTime(new Date(year, 8, 22, 12)) - getTime(fallEquinox)
		const daysDiff3 = Math.ceil(timeDiff3 / (1000 * 60 * 60 * 24))

		// Add the number of days to September 1st to get the date of the fall equinox
		const fallEquinoxDate = addDays(fallEquinox, daysDiff3)

		const winterSolstice = addMonths(startOfYear(new Date(year)), 11) // Start with December 1st
		winterSolstice.setHours(12) // Set time to 12:00 PM to account for time zones

		// Calculate the number of days between December 1st and the winter solstice
		const timeDiff4 = getTime(new Date(year, 11, 21, 12)) - getTime(winterSolstice)
		const daysDiff4 = Math.ceil(timeDiff4 / (1000 * 60 * 60 * 24))

		// Add the number of days to December 1st to get the date of the winter solstice
		const winterSolsticeDate = addDays(winterSolstice, daysDiff4)

		return {
			springEquinox: springEquinoxDate,
			summerSolstice: summerSolsticeDate,
			fallEquinox: fallEquinoxDate,
			winterSolstice: winterSolsticeDate,
		}
	}

	getLastSeasonDateRange(
		season: 'summer' | 'winter' | 'spring' | 'autumn' | 'fall',
		isNorthernHemisphere: boolean
	): Range<Date> {
		const year = new Date().getFullYear() // Replace with the desired year
		const currentDate = new Date()

		const { fallEquinox, springEquinox, summerSolstice, winterSolstice } = this.getSeasonDates(year)
		const {
			fallEquinox: lastFallEquinox,
			springEquinox: lastSpringEquinox,
			summerSolstice: lastSummerSolstice,
			winterSolstice: lastWinterSolstice,
		} = this.getSeasonDates(year - 1)

		switch (season.toLowerCase()) {
			case 'spring':
				if (isNorthernHemisphere) {
					if (currentDate > summerSolstice) {
						return {
							from: springEquinox,
							to: summerSolstice,
						}
					} else {
						return {
							from: lastSpringEquinox,
							to: lastSummerSolstice,
						}
					}
				} else {
					if (currentDate > winterSolstice) {
						return {
							from: fallEquinox,
							to: winterSolstice,
						}
					} else {
						return {
							from: lastFallEquinox,
							to: lastWinterSolstice,
						}
					}
				}
			case 'summer':
				if (isNorthernHemisphere) {
					if (currentDate > fallEquinox) {
						return {
							from: summerSolstice,
							to: fallEquinox,
						}
					} else {
						return {
							from: lastSummerSolstice,
							to: lastFallEquinox,
						}
					}
				} else {
					if (currentDate > springEquinox) {
						return {
							from: winterSolstice,
							to: springEquinox,
						}
					} else {
						return {
							from: lastWinterSolstice,
							to: lastSpringEquinox,
						}
					}
				}
			case 'fall':
			case 'autumn':
				if (isNorthernHemisphere) {
					if (currentDate > winterSolstice) {
						return {
							from: fallEquinox,
							to: winterSolstice,
						}
					} else {
						return {
							from: lastFallEquinox,
							to: lastWinterSolstice,
						}
					}
				} else {
					if (currentDate > summerSolstice) {
						return {
							from: springEquinox,
							to: summerSolstice,
						}
					} else {
						return {
							from: lastSpringEquinox,
							to: lastSummerSolstice,
						}
					}
				}
			case 'winter':
				if (isNorthernHemisphere) {
					if (currentDate > springEquinox) {
						return {
							from: lastWinterSolstice,
							to: springEquinox,
						}
					} else {
						return {
							from: this.getSeasonDates(year - 2).winterSolstice,
							to: lastSpringEquinox,
						}
					}
				} else {
					if (currentDate > fallEquinox) {
						return {
							from: summerSolstice,
							to: fallEquinox,
						}
					} else {
						return {
							from: lastSummerSolstice,
							to: lastFallEquinox,
						}
					}
				}
			default:
				throw new Error('Invalid season provided.')
		}
	}

	maxDateApply(date: Date, maxDate?: Date) {
		if (maxDate == undefined) return date

		return isAfter(date, maxDate) ? maxDate : date
	}

	getDatesFromDateGranularity = (
		dateRange: Range<Date> | DateGranularity,
		parameters?: {
			emisphere?: Emisphere
			masterFilterRange?: Range<Date> | DateGranularity
			maxDate?: Date
		}
	): { from: Date; to: Date } => {
		// returns the date range as it is if the values are already a range
		if (typeof dateRange === 'object') {
			return dateRange
		}

		// granularities
		const dateGran = dateRange as DateGranularity

		switch (dateGran) {
			case DateGranularity.WeekToDate:
				return {
					from: startOfWeek(new Date(), { weekStartsOn: 1 }),
					to: new Date(),
				}
			case DateGranularity.MonthToDate:
				return {
					from: startOfMonth(new Date()),
					to: new Date(),
				}
			case DateGranularity.YearToDate:
				return {
					from: startOfYear(new Date()),
					to: new Date(),
				}
			case DateGranularity.Next7Days:
				return {
					from: new Date(),
					to: this.maxDateApply(addDays(new Date(), 7), parameters?.maxDate),
				}
			case DateGranularity.Next30Days:
				return {
					from: new Date(),
					to: this.maxDateApply(addDays(new Date(), 30), parameters?.maxDate),
				}
			case DateGranularity.Next60Days:
				return {
					from: new Date(),
					to: this.maxDateApply(addDays(new Date(), 60), parameters?.maxDate),
				}
			case DateGranularity.Next90Days:
				return {
					from: new Date(),
					to: this.maxDateApply(addDays(new Date(), 90), parameters?.maxDate),
				}
			case DateGranularity.CurrentYear:
				return {
					from: startOfYear(new Date()),
					to: this.maxDateApply(endOfYear(new Date()), parameters?.maxDate),
				}
			case DateGranularity.Last7Days:
				return {
					from: subDays(new Date(), 7),
					to: new Date(),
				}
			case DateGranularity.Last30Days:
				return {
					from: subDays(new Date(), 30),
					to: new Date(),
				}
			case DateGranularity.Last60Days:
				return {
					from: subDays(new Date(), 60),
					to: new Date(),
				}
			case DateGranularity.Last90Days:
				return {
					from: subDays(new Date(), 90),
					to: new Date(),
				}
			case DateGranularity.Last6Months:
				return {
					from: subMonths(new Date(), 6),
					to: new Date(),
				}
			case DateGranularity.SinceEver:
				return {
					from: new Date(0),
					to: new Date(),
				}
			case DateGranularity.Ever:
				return {
					from: new Date(0),
					to: this.maxDateApply(new Date('2100-01-01'), parameters?.maxDate),
				}
			case DateGranularity.LastWinter:
			case DateGranularity.LastSpring:
			case DateGranularity.LastSummer:
			case DateGranularity.LastFall:
				const season = dateGran.split('_')[1] as Parameters<typeof this.getLastSeasonDateRange>[0]

				const isNorthernHemisphere = parameters?.emisphere ? parameters?.emisphere === Emisphere.North : true
				const seasonDateRange = this.getLastSeasonDateRange(season, isNorthernHemisphere)

				return seasonDateRange
			default:
				return {
					from: new Date(),
					to: new Date(),
				}
		}
	}
}

export const utilDate = new UtilDate()
