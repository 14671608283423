import { Accommodation, ChannelManagerInfo } from '@/types/Accommodation'
import { defineStore } from 'pinia'
import { ChannelManagerName } from '~/submodules/sharedTypes/common/ChannelManager'
import { TimeseriesLineDataPoint } from '~/submodules/sharedTypes/common/DataPoint'
import { SalesModesConfig } from '../config/SalesModesConfig'
import { LoadingIds } from '../constants/loadingIds'
import { SalesModes } from '../constants/salesMode'
import { StorageKeys } from '../constants/storageKeys'
import { anonimyzeAccommodations, anonymizeRooms } from '../devMode/salesModes'
import { GetAccommodationsNetworkObject } from '../submodules/sharedTypes/communication/accommodations/GetAccommodationsNetworkObject'
import { GetAccommodationsRequest } from '../submodules/sharedTypes/communication/accommodations/GetAccommodationsRequest'
import { GetAccommodationsResponse } from '../submodules/sharedTypes/communication/accommodations/GetAccommodationsResponse'
import { GetAccommodationSoldNightsRangeNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/reservations/GetAccommodationSoldNightsRangeNetworkObject'
import { GetAccommodationSoldNightsRangeResponse } from '../submodules/sharedTypes/communication/accommodations/id/reservations/GetAccommodationSoldNightsRangeResponse'
import { GetRoomTypeSoldNightsRangeNetworkObject } from '../submodules/sharedTypes/communication/room-types/id/reservations/GetRoomTypeSoldNightsRangeNetworkObject'
import { GetRoomTypeSoldNightsRangeResponse } from '../submodules/sharedTypes/communication/room-types/id/reservations/GetRoomTypeSoldNightsRangeResponse'
import { SharedTrackingProperties } from '../submodules/sharedTypes/tracking/SharedTrackingProperties'
import { UserProfileTrackingProperties } from '../submodules/sharedTypes/tracking/UserProfileTrackingProperties'
import { utilNetwork } from '../utils/UtilNetwork'
import { Currency } from './../submodules/sharedTypes/common/Currency'
import { RoomType } from './../submodules/sharedTypes/common/RoomType'
import { UtilSales } from './../utils/UtilSales'
import { utilTracking } from './../utils/utilTracking'
import { useLoadingStore } from './loading'
import { useVisualizationsStore } from './visualizations'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { UpdateRoomTypesOrderResponse } from '~/submodules/sharedTypes/communication/room-types/UpdateRoomTypesOrderResponse'
import { hash } from 'v-calendar/dist/types/src/utils/helpers.js'

type PropertyState = {
	accommodations: Accommodation[]
	roomTypes: RoomType[]
	selectedAccommodationIndex: number
	selectedRoomTypeIndex: number
	accommodationsToShow: string[]
}

export const useAccommodationsStore = defineStore('⚙️ accommodations', {
	state: (): PropertyState => ({
		accommodations: [],
		roomTypes: [],
		selectedAccommodationIndex: -1,
		selectedRoomTypeIndex: -1,
		accommodationsToShow: [],
	}),
	actions: {
		setAccommodationsToShow(accommodationsToShow: string[]) {
			this.accommodationsToShow = accommodationsToShow
		},
		getAccommodations(params: GetAccommodationsRequest) {
			useLoadingStore().addLoading(LoadingIds.ACCOMMODATIONS)
			return utilNetwork.simpleRequest(new GetAccommodationsNetworkObject(params), this.setAccommodations)
		},
		setRoomTypesOrder(serverResponse: UpdateRoomTypesOrderResponse) {
			serverResponse.accommodationId

			const roomTypes = this.accommodations.find(
				(accommodation) => accommodation.id === serverResponse.accommodationId
			)?.roomTypes

			if (roomTypes !== undefined) {
				const roomTypesHashMap = Object.fromEntries(roomTypes.map((roomType) => [roomType.id, roomType]))

				const orderRoomTypes = serverResponse.roomTypesIds
					.map((roomTypeId) => roomTypesHashMap[roomTypeId])
					.filter((roomType) => roomType !== undefined) as RoomType[]

				const index = this.accommodations.findIndex(
					(accommodation) => accommodation.id === serverResponse.accommodationId
				)

				this.accommodations[index].roomTypes = orderRoomTypes

				this.roomTypes = this.accommodations
					.map((element) => element.roomTypes || [])
					.reduce((prev, next) => prev.concat(next))
					.map((roomType) => {
						delete roomType.oldestSoldNightDate
						return roomType
					})
			}
		},
		setAccommodations(serverResponse: GetAccommodationsResponse) {
			if (serverResponse.accommodations.length == 0) {
				useLoadingStore().removeLoading(LoadingIds.ACCOMMODATIONS)
				return
			}

			if (UtilSales.isSalesMode.value) {
				const selectedMode = localStorage.getItem(StorageKeys.SalesMode)! as SalesModes
				const config = SalesModesConfig[selectedMode]

				anonimyzeAccommodations(config, serverResponse.accommodations)
				anonymizeRooms(config, serverResponse.accommodations, [])
			}

			this.accommodations = serverResponse.accommodations.map((accommodation) => {
				delete accommodation.oldestSoldNightDate
				return accommodation
			})
			this.roomTypes = serverResponse.accommodations
				.map((element) => element.roomTypes || [])
				.reduce((prev, next) => prev.concat(next))
				.map((roomType) => {
					delete roomType.oldestSoldNightDate
					return roomType
				})

			// const visualizationStore = useVisualizationsStore()
			// visualizationStore.availableVisualizations.forEach((visualization) => {
			// 	visualization.filters.forEach((filter) => {
			// 		if (filter.accommodationId === -1) {
			// 			filter.accommodationId = this.accommodations[0].id
			// 		}
			// 	})
			// })

			useLoadingStore().removeLoading(LoadingIds.ACCOMMODATIONS)

			utilTracking.setSharedProperty(
				SharedTrackingProperties.AccommodationsCount,
				this.accommodations.length.toString()
			)
			utilTracking.setSharedProperty(
				SharedTrackingProperties.RoomTypesCount,
				this.accommodations.reduce((prev, next) => prev + next.numUnits, 0).toString()
			)
			utilTracking.setSharedProperty(
				SharedTrackingProperties.RoomTypesCount,
				this.accommodations.reduce((prev, next) => prev + next.numUnits, 0).toString()
			)
			utilTracking.setSharedProperty(
				SharedTrackingProperties.VisibleRoomTypesCount,
				this.accommodations.reduce((prev, next) => prev + next.roomTypes!.length, 0).toString()
			)
			const accommodationsIds = this.accommodations.map((accommodation) => accommodation.id)
			const accommodationsNames = this.accommodations.map((accommodation) => accommodation.name)
			const hasMarketCorrectionAvailable = this.accommodations.some(
				// @ts-ignore
				(accommodation) => accommodation.hasMarketCorrection
			)

			utilTracking.setSharedProperty(SharedTrackingProperties.AccommodationsIds, accommodationsIds)
			utilTracking.setSharedProperty(SharedTrackingProperties.AccommodationsNames, accommodationsNames)
			utilTracking.setUserProperty(UserProfileTrackingProperties.AccommodationsIds, accommodationsIds)
			utilTracking.setUserProperty(UserProfileTrackingProperties.AccommodationsNames, accommodationsNames)
			utilTracking.setUserProperty(
				UserProfileTrackingProperties.MarketOccupancyAvailable,
				this.canUserSeeMarketOccupancy
			)
			utilTracking.setUserProperty(
				UserProfileTrackingProperties.GapFillingAvailablePropertiesCount,
				this.accommodations.filter((accommodation) => accommodation.isOrphanNightsManagementAvailable).length
			)
			utilTracking.setUserProperty(
				UserProfileTrackingProperties.GapFillingEnabledPropertiesCount,
				this.accommodations.filter((accommodation) => accommodation.settings?.orphanNights.enabled).length
			)
			utilTracking.setUserProperty(
				UserProfileTrackingProperties.MarketCorrectionAvailable,
				hasMarketCorrectionAvailable
			)
		},
		requestSoldNightsRange(accommodationId: number) {
			// first we need to look if sold nights have already been loaded,
			// in that case there is no need to request the date range again
			const accommodation = this.accommodations.find((accommodation) => accommodation.id === accommodationId)
			if (!accommodation?.oldestSoldNightDate) {
				utilNetwork.simpleRequest(
					new GetAccommodationSoldNightsRangeNetworkObject({
						accommodationId,
					})
				)
			}
		},
		setSoldNightsRange(params: GetAccommodationSoldNightsRangeResponse) {
			const accommodation = this.accommodations.find((accommodation) => accommodation.id === params.accommodationId)
			if (accommodation) {
				accommodation.oldestSoldNightDate = params.soldNightsRange.from
			}
		},
		requestRoomTypeSoldNightsRange(roomTypeId: number) {
			// first we need to look if sold nights have already been loaded
			// for the given room type, in that case there is no need to request the date range again
			const roomType = this.roomTypes.find((roomType) => roomType.id === roomTypeId)
			if (!roomType?.oldestSoldNightDate) {
				utilNetwork.simpleRequest(
					new GetRoomTypeSoldNightsRangeNetworkObject({
						roomTypeId,
					})
				)
			}
		},
		setRoomTypeSoldNightsRange(params: GetRoomTypeSoldNightsRangeResponse) {
			const roomType = this.roomTypes.find((roomType) => roomType.id === params.roomTypeId)
			if (roomType) {
				roomType.oldestSoldNightDate = params.soldNightsRange.from
			}
		},
		setChannelManagerInfo(id: number, channelManager: ChannelManagerName, info: ChannelManagerInfo) {
			const accommodation = this.accommodations.find((accommodation) => accommodation.id === id)

			if (accommodation) {
				accommodation.channelManagersInfo = {
					[channelManager]: info,
				}
			}
		},
		setMeanMarketTrend(id: number, chartData: TimeseriesLineDataPoint) {
			const accommodation = this.accommodations.find((accommodation) => accommodation.id === id)
			const { timestamps, values } = chartData

			if (accommodation) {
				// @ts-ignore
				accommodation.meanMarketTrendData = timestamps.map((timestamp, index) => ({
					time: timestamp,
					value: values[index],
				}))
			}
		},
		setOrphanNightsSettings(accommodationId: number, roomTypeIds: number[]) {
			const accommodation = this.accommodations.find((accommodation) => accommodation.id === accommodationId)

			if (accommodation) {
				accommodation.settings.isOrphanNightsManagementEnabled = roomTypeIds.length > 0

				this.roomTypes
					.filter((roomType) => roomType.accommodationId === accommodationId)
					.forEach((roomType) => {
						roomType.settings.isOrphanNightsManagementEnabled = roomTypeIds.includes(roomType.id)
					})
			}
		},
	},
	getters: {
		getAccommodationChannelManager(): string[] {
			return utilArray.removeDuplicates(
				this.accommodations
					.map((accommodation) => accommodation.cm)
					.filter((channelManager) => channelManager !== undefined)
			)
		},

		basePricesRoomTypesEnabled() {
			return (accommodationId: number) => {
				const accommodation = this.accommodations.find((accommodation) => accommodation.id === accommodationId)

				if (accommodation) {
					return accommodation.roomTypes?.filter((roomType) => !roomType.isIndexed && roomType.isPredictionEnabled)
				}
				return []
			}
		},
		visibleAccommodations(): Accommodation[] {
			const showingAccomm = useUserPreferencesStore().userPreferences.focusMode?.active ?? []

			if (!(showingAccomm?.length > 0) || useMobileViewport().isMobile.value) return this.accommodations

			return this.accommodations.filter((accommodation: Accommodation) =>
				showingAccomm.includes(accommodation.id.toString())
			)
		},
		visibleRoomTypes(): RoomType[] {
			const showingRoomTypes = useUserPreferencesStore().userPreferences.focusMode?.active ?? []

			if (!(showingRoomTypes?.length > 0) || useMobileViewport().isMobile.value) return this.roomTypes

			return this.visibleAccommodations.map((accommodation: Accommodation) => accommodation.roomTypes || []).flat()
		},
		canUserSeeMarketOccupancy(): boolean {
			return this.accommodations.length > 0 && this.accommodations.some((acc) => acc.canShowMarketDemand)
		},
		areAccommodationsStatusPreOnboarding(): boolean {
			return this.accommodations.every((accommotion) => accommotion.status === 'preonboarding')
		},
		getDefaultAccommodation(): Accommodation | undefined {
			return this.selectedAccommodationIndex >= 0 ? this.accommodations[0] : undefined
		},
		getCurrentAccommodation(): Accommodation {
			const index = Math.max(this.selectedAccommodationIndex, 0)
			return this.accommodations[index]
		},
		getAccommodationById() {
			return (id: number | undefined | string): Accommodation | undefined => {
				if (id == -1) {
					return this.accommodations[0]
				}
				return this.accommodations.find((el) => el.id === id)
			}
		},
		getRoomTypeCurrency() {
			return (id: number): Currency | undefined => {
				return this.accommodations.find((el) => (el.roomTypes || []).find((room) => room.id === id) != undefined)
					?.currency
			}
		},
		getAccommodationNameOrDefault() {
			return (id?: number): string => {
				if (id == undefined || id == -1) {
					return this.accommodations[0]?.name || ''
				}

				return this.getAccommodationById(id)?.name || ''
			}
		},
		getCurrentRoomType(): RoomType | undefined {
			return this.selectedRoomTypeIndex >= 0 ? this.roomTypes[this.selectedRoomTypeIndex] : undefined
		},
		getCurrentRoomTypes(): RoomType[] {
			const index = Math.max(this.selectedAccommodationIndex, 0)
			return this.accommodations[index]?.roomTypes || []
		},
		getRoomTypeById() {
			return (id: number | undefined): RoomType | undefined => {
				return this.roomTypes.find((el) => el.id === id)
			}
		},
		getRoomTypesByAccommodationId() {
			return (id: number) => this.getAccommodationById(id)?.roomTypes || []
		},
		getRoomTypeNameOrDefault() {
			return (id?: number): string => {
				if (id == undefined || id == -1) {
					return useLocale().translate(TranslationKeys.WHOLE_STRUCTURE)
				}

				return this.getRoomTypeById(id)?.name || ''
			}
		},
		getName() {
			return (roomTypeId: number | undefined, accommodationId: number | undefined): string => {
				const roomType = this.getRoomTypeById(roomTypeId)
				if (roomType != undefined) {
					return roomType.name
				}

				const accommodation = this.getAccommodationById(accommodationId)
				if (accommodation != undefined) {
					return accommodation.name
				}

				return useLocale().translate(TranslationKeys.WHOLE_STRUCTURE)
			}
		},
		isMonoStructure(): boolean {
			return this.accommodations.length == 1
		},
		isAccommodationStrategyDerived() {
			return (accommodationId: number) => {
				const accommodation = this.getAccommodationById(accommodationId)
				return !!accommodation?.parentStrategyAccommodationId
			}
		},
	},
})
