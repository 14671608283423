import { StoreDefinition, defineStore } from 'pinia'
import { useAccommodationsStore } from './accommodations'
import _, { isEqual } from 'lodash'
import { RoomType } from '../submodules/sharedTypes/common/RoomType'
import { Accommodation } from '../types/Accommodation'
import { BasePriceSourceType } from '../types/StartingPrices'
import { defaultVariationSettings } from '@/store/basePricesNew'
import { Modifiers } from '@/submodules/sharedTypes/common/Modifiers'
import { GetAccommodationGlobalModifiersResponse } from '@/submodules/sharedTypes/communication/modifiers/GetAccommodationGlobalModifiersResponse'
import { UpdateAccommodationGlobalModifiersNetworkObject } from '@/submodules/sharedTypes/communication/modifiers/UpdateAccommodationGlobalModifiersNetworkObject'
import { GetAccommodationGlobalModifiersNetworkObject } from '@/submodules/sharedTypes/communication/modifiers/GetAccommodationGlobalModifiersNetworkObject'
import { use } from 'echarts/types/src/extension.js'

export type GeneralInfo = {
	name: string
	address: string
	proprtyType: string
	propertyRating: string
	propertyFacilities: string[]
}

export const useSettingsStore = defineStore('⚙️ settings', () => {
	const sectionToStore: Record<string, StoreDefinition> = {
		'gap-filling': useOrphanNightsStore,
		'starting-prices': useBasePricesStore,
	}
	const accommodationsStore = useAccommodationsStore()
	const userPreferencesStore = useUserPreferencesStore()
	const { userPreferencesAccommodationSettingsPage } = storeToRefs(userPreferencesStore)

	const selectedTab = ref<string | undefined>(undefined)

	// Generic service info, selected accommodation, room types, etc...
	const selectedAccommodation = ref<Accommodation | undefined>(accommodationsStore.accommodations[0])
	const setSelectedAccommodation = (accommodationId: Accommodation['id']) => {
		selectedAccommodation.value =
			accommodationsStore.accommodations.find((accommodation) => accommodation.id === accommodationId) || undefined
	}
	const selectedRoomType = ref<RoomType | undefined>(undefined)
	const setSelectRoomType = (roomTypeId: RoomType['id'], shouldToggle = true) => {
		if (selectedRoomType.value?.id === roomTypeId && shouldToggle) {
			selectedRoomType.value = undefined

			// Update user preference
			useUserPreferencesStore().setUserPreferences({
				accommodationSettingsPage: {
					activeAccommodationId: undefined,
					activeRoomTypeId: undefined,
				},
			})

			return
		}

		selectedRoomType.value =
			selectedAccommodation.value?.roomTypes?.find((roomType) => roomType.id === roomTypeId) || undefined

		// Set base prices settings
		useBasePricesStore().setCurrentBasePriceSource(BasePriceSourceType.StartingPrices)
		useBasePricesStore().setCurrentVariationSettings(
			isEqual(useBasePricesStore().basePricesVariationsEdited.get(roomTypeId), defaultVariationSettings) ||
				useBasePricesStore().basePricesVariationsEdited.get(roomTypeId) === undefined
				? undefined
				: useBasePricesStore().basePricesVariations.get(roomTypeId)
		)

		// Update user preference
		useUserPreferencesStore().setUserPreferences({
			accommodationSettingsPage: {
				activeAccommodationId: selectedAccommodation.value?.id,
				activeRoomTypeId: roomTypeId,
			},
		})
	}

	const setSelectedTab = (tab: string | undefined) => {
		selectedTab.value = tab
	}

	const getSelectedTab = computed(() => {
		return selectedTab.value
	})

	// General info
	const accommodationGeneralInfo = ref<GeneralInfo>({
		name: 'Hotel Bello',
		address: 'Via Roma 123, 00100, Roma',
		proprtyType: 'hotel',
		propertyRating: '3stars',
		propertyFacilities: ['wifi', 'parking', 'restaurant'],
	})
	const editMode = ref(false)
	const accommodationGeneralInfoToEdit = ref<GeneralInfo | undefined>(undefined)
	const setGeneralInfo = (info: GeneralInfo) => {
		accommodationGeneralInfo.value = info
	}
	const saveGeneralInfo = () => {
		accommodationGeneralInfo.value = accommodationGeneralInfoToEdit.value
		editMode.value = false
	}
	const toggleEditMode = () => {
		if (!editMode.value) {
			accommodationGeneralInfoToEdit.value = _.cloneDeep(toRaw(accommodationGeneralInfo.value))
			editMode.value = true
		} else {
			editMode.value = false
			accommodationGeneralInfoToEdit.value = undefined
		}
	}

	// Global min and max prices
	const globalMinMaxPrices: Ref<Record<number, Modifiers>> = ref({})
	const originalGlobalMinMaxPrices: Ref<Record<number, Modifiers>> = ref({})

	const setGlobalMinMaxPrices = (data: GetAccommodationGlobalModifiersResponse) => {
		globalMinMaxPrices.value = {}
		originalGlobalMinMaxPrices.value = {}
		data.roomTypeModifiers.forEach((roomTypeModifier) => {
			globalMinMaxPrices.value[roomTypeModifier.roomTypeId] = roomTypeModifier.modifiers
			originalGlobalMinMaxPrices.value[roomTypeModifier.roomTypeId] = roomTypeModifier.modifiers
		})
	}

	const fetchGlobalMinMaxPrices = () => {
		if (!selectedAccommodation.value) return

		utilNetwork.simpleRequest(
			new GetAccommodationGlobalModifiersNetworkObject({
				accommodationId: selectedAccommodation.value?.id,
			})
		)
	}

	const editGlobalMinMaxPrice = (modifiers: Modifiers, roomTypeId?: number) => {
		if (roomTypeId === undefined) return
		globalMinMaxPrices.value[roomTypeId] = modifiers
	}

	const saveGlobalMinMaxPrice = () => {
		// save request which should trigger the setGlobalMinMaxPrices method
		utilTracking.track('General max min modifiers set', {})

		utilNetwork.simpleRequest(
			new UpdateAccommodationGlobalModifiersNetworkObject({
				accommodationId: selectedAccommodation.value?.id || 0,
				roomTypeModifiers: Object.entries(globalMinMaxPrices.value).map(([roomTypeId, modifiers]) => ({
					roomTypeId: Number(roomTypeId),
					modifiers,
				})),
			})
		)
	}

	const save = () => {
		if (!selectedTab.value) return

		sectionToStore[selectedTab.value]().save()
	}

	const resetChanges = () => {
		if (!selectedTab.value) return

		sectionToStore[selectedTab.value]().resetChanges()
	}

	// Save handling
	const checkForChanges = computed(() => {
		if (!selectedTab.value) return false

		return sectionToStore[selectedTab.value]().checkForChanges
	})

	const checkForErrors = computed(() => {
		if (!selectedTab.value) return false

		return sectionToStore[selectedTab.value]().checkForErrors ?? false
	})

	const $reset = () => {
		selectedAccommodation.value = accommodationsStore.accommodations[0]
		selectedRoomType.value = undefined
		accommodationGeneralInfo.value = {
			name: 'Hotel Bello',
			address: 'Via Roma 123, 00100, Roma',
			proprtyType: 'hotel',
			propertyRating: '3stars',
			propertyFacilities: ['wifi', 'parking', 'restaurant'],
		}
		editMode.value = false
		accommodationGeneralInfoToEdit.value = undefined
		globalMinMaxPrices.value = {}
		originalGlobalMinMaxPrices.value = {}
	}

	return {
		selectedAccommodation,
		setSelectedAccommodation,
		selectedRoomType,
		setSelectRoomType,

		globalMinMaxPrices,
		originalGlobalMinMaxPrices,
		setGlobalMinMaxPrices,
		fetchGlobalMinMaxPrices,
		editGlobalMinMaxPrice,
		saveGlobalMinMaxPrice,

		accommodationGeneralInfo,
		editMode,
		accommodationGeneralInfoToEdit,
		toggleEditMode,
		setGeneralInfo,
		saveGeneralInfo,

		save,
		resetChanges,
		checkForChanges,
		checkForErrors,

		setSelectedTab,
		getSelectedTab,

		$reset,
	}
})
