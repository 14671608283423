import { AdditionalWebSocketParams } from '../communication/additionalParams/AdditionalWebSocketParams';

export const enum WSMessageEvent {
  Connect = 'connect',

  // client side
  GetAccommodationsPrices = 'get_accommodations_prices',
  GetAccommodationsOccupancies = 'get_accommodations_occupancies',
  GetAccommodationsEvents = 'get_accommodations_events',
  GetAccommodationDateDetails = 'get_accommodation_date_details',
  GetAccommodationsPricesSent = 'get_accommodations_prices_sent',
  GetRoomTypeDateDetails = 'get_room_type_date_details',
  CalculateQuotation = 'calculate_quotation',
  GetRoomTypesEnabledModifiers = 'get_room_types_enabled_modifiers',
  GetAccommodationGlobalModifiers = 'get_accommodation_global_modifiers',
  UpdateAccommodationGlobalModifiers = 'update_accommodation_global_modifiers',
  UpdateModifiers = 'update_modifiers',
  BulkUpdateModifiers = 'bulk_update_modifiers',
  BulkUpdateModifiersMultipleDates = 'bulk_update_modifiers_multiple_dates',
  BulkRemoveModifiersMultipleDates = 'bulk_remove_modifiers_multiple_dates',
  BulkUpdateModifiersMultipleRoomTypes = 'bulk_update_modifiers_multiple_room_types',
  BulkRemoveModifiers = 'bulk_remove_modifiers',
  GetAccommodationStrategy = 'get_accommodation_strategy_v2',
  UpdateAccommodationStrategy = 'update_accommodation_strategy_v2',
  GetAdvancedExpertUserParamsMapping = 'get_advanced_expert_user_params_mapping',
  GetAccommodationPricesPredictionPreview = 'get_accommodation_prices_prediction_preview',
  GetAccommodationEnabledTweakModifier = 'get_accommodation_enabled_tweak_modifier',
  GetUserSettings = 'get_user_settings',
  SetUserLastReleaseNotesVersionViewed = 'set_user_last_release_notes_version_viewed',
  UpdateUserSettings = 'update_user_settings',
  GetReleaseNotesMonthlyGroups = 'get_release_notes_monthly_groups',
  GetLatestReleaseNotesVersion = 'get_latest_release_notes_version',
  GetLatestReleaseNotes = 'get_latest_release_notes',
  GetReleaseNotes = 'get_release_notes',
  GetRoomTypesPricesDetails = 'get_room_types_prices_details',
  GetEventDetails = 'get_event_details',
  GetEventImpactPreview = 'get_event_impact_preview',
  CreateEvent = 'create_event',
  UpdateEvent = 'update_event',
  RemoveEvent = 'remove_event',
  ToggleEventVisibility = 'toggle_event_visibility',
  GetAccommodationsMarketDemand = 'get_accommodations_market_demand',
  GetAccommodationBasePrices = 'get_accommodation_base_prices',
  GetAccommodationBasePricesPredictionPreview = 'get_accommodation_base_prices_prediction_preview',
  UpdateAccommodationBasePrices = 'update_accommodation_base_prices',
  GetOnboardingProfileLastUpdate = 'get_onboarding_profile_last_update',
  SetOnboardingProfileUpdate = 'set_onboarding_profile_update',
  GenerateOnboardingProfile = 'generate_onboarding_profile',
  UploadOnboardingBasePrices = 'upload_onboarding_base_prices',
  GetAccommodationSoldNightsRange = 'get_accommodation_sold_nights_range',
  GetRoomTypeSoldNightsRange = 'get_room_type_sold_nights_range',
  GetBillingProfiles = 'get_billing_profiles',
  UpdateBillingInfo = 'update_billing_info',
  GetInvoices = 'get_invoices',
  GetOrphanNightsSettings = 'get_orphan_nights_settings',
  GetOrphanNightsFixedDates = 'get_orphan_nights_fixed_dates',
  UpdateOrphanNightsSettings = 'update_orphan_nights_settings',
  GetInvoiceYears = 'get_invoice_years',
  GetAvailablePartners = 'get_available_partners',
  GetPartnerAccessInfoSchema = 'get_partner_access_info_schema',
  GetOnboardingProgress = 'get_onboarding_progress',
  SetOnboardingProgress = 'set_onboarding_progress',
  GetUserPreferences = 'get_user_preferences',
  SetUserPreferences = 'set_user_preferences',
  UpdateRoomTypesOrder = 'update_room_types_order',

  // server side
  PricesUpdate = 'prices_update',
  AccommodationDateDetailsStats = 'accommodation_date_details_stats',
  AccommodationDateDetailsBookingOccupancies = 'accommodation_date_details_booking_occupancies',
  RoomTypeDateDetailsPrice = 'room_type_date_details_price',
  RoomTypeDateDetailsEvents = 'room_type_date_details_events',
  RoomTypeDateDetailsStats = 'room_type_date_details_stats',
  RoomTypeDateDetailsPriceComputationHistory = 'room_type_date_details_price_computation_history',
  RoomTypeDateDetailsBookingEvents = 'room_type_date_details_booking_events',
  RoomTypeDateDetailsBookingOccupancies = 'room_type_date_details_booking_occupancies',
  RoomTypesPricesPrediction = 'room_types_prices_prediction',
  WSError = 'ws_error',
  WSPricesPredictionPreviewError = 'ws_prices_prediction_preview_error',
  WSSavePricesPredictionError = 'ws_save_prices_prediction_error',
  WSTokenExpiredErrorOnConnection = 'ws_token_expired_error_on_connection',
  WSTokenExpiredErrorOnMessage = 'ws_token_expired_error_on_message',
}

export type WSMessage<T> = {
  event: WSMessageEvent;
  data: T;
  smartpricingParams?: AdditionalWebSocketParams;
};
