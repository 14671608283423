import { CardTypes } from '../constants/cardTypes'
import { LoadingIds } from '../constants/loadingIds'
import { useBasePricesStore } from '../store/basePricesNew'
import { useOccupancyStore } from '../store/occupancy'
import { WsServerMessageEvent } from '../submodules/sharedTypes/common/WebSocketServer'
import { useBannerStore } from './../store/banners'
import { useCalculatedQuotationsStore } from './../store/calculatedQuotation'
import { useDateDetailsStore } from './../store/dateDetails'
import { useEventsStore } from './../store/events'
import { useMarketDemandStore } from './../store/marketDemand'
import { useModifiersStore } from './../store/modifiers'
import { usePricesStore } from './../store/prices'
import { useStrategiesStore } from './../store/strategies'
import { useUserSettingsStore } from './../store/userSettings'
import { utilNetwork } from './../utils/UtilNetwork'
import { utilWebSocket } from './../utils/UtilWebSocket'
import { useOnboardingStore } from '../store/onboarding'
import { useAccommodationsStore } from '~/store/accommodations'
import { useBillingStore } from '~/store/billing'
import { useAutoOnboardingStore } from '../store/autoOnboarding'
import { handleDerivedStrategyBanner } from '../utils/utilStrategy'
import { useUserPreferencesStore } from '../store/userPreferences'
import { useOrphanNightsStore } from '../store/orphanNights'
import { useNotificationsStore } from '../store/notifications'
import { IMessageCardData } from '../types/IMessageCardData'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { TestIds } from '@/constants/TestIds'
import { GetUserPreferencesResponse } from '~/submodules/sharedTypes/communication/user-preferences/GetUserPreferences/GetUserPreferencesResponse'

export interface ISocketHandler {
	handler: (params: any, connectionString: string) => void
	loadingId?: LoadingIds
}

export const socketListenerMap: {
	[key in WsServerMessageEvent]: ISocketHandler
} = {
	[WsServerMessageEvent.CalculateQuotation]: {
		handler: (params) => useCalculatedQuotationsStore().setQuotation(params),
		loadingId: LoadingIds.CALCULATED_QUOTATIONS,
	},
	[WsServerMessageEvent.GetAccommodationsEvents]: {
		handler: (params) => useEventsStore().setEvents(params),
		loadingId: LoadingIds.EVENTS,
	},
	[WsServerMessageEvent.GetAccommodationsOccupancies]: {
		handler: (params) => useOccupancyStore().addOccupancies(params),
		loadingId: LoadingIds.OCCUPANCY,
	},
	[WsServerMessageEvent.GetAccommodationsPrices]: {
		handler: (params) => usePricesStore().addAccommodationPrices(params),
		loadingId: LoadingIds.PRICES,
	},
	[WsServerMessageEvent.PricesUpdate]: {
		handler: (params) => {
			usePricesStore().addAccommodationPrices(params)
			useDateDetailsStore().updateDetailsRequirements(params)
		},
		loadingId: LoadingIds.PRICES,
	},
	[WsServerMessageEvent.UpdateRoomTypesOrder]: {
		handler: (params) => {
			useAccommodationsStore().setRoomTypesOrder(params)
		},
	},
	[WsServerMessageEvent.RoomTypeDateDetailsEvents]: {
		handler: (params) => useDateDetailsStore().setRoomTypeEvents(params),
	},
	[WsServerMessageEvent.GetRoomTypesEnabledModifiers]: {
		handler: (params) => useModifiersStore().setModifiers(params),
	},
	[WsServerMessageEvent.AccommodationDateDetailsStats]: {
		handler: (params) => useDateDetailsStore().setAccommodationDateDetails(params),
	},
	[WsServerMessageEvent.RoomTypeDateDetailsPrice]: {
		handler: (params) => {
			useDateDetailsStore().setRoomTypePriceDateDetailsResponse(params)
		},
	},
	[WsServerMessageEvent.RoomTypeDateDetailsBookingEvents]: {
		handler: (params) => useDateDetailsStore().setRoomTypeReservations(params),
	},
	[WsServerMessageEvent.RoomTypeDateDetailsPriceComputationHistory]: {
		handler: (params) => useDateDetailsStore().setComputedPrices(params),
	},
	[WsServerMessageEvent.RoomTypeDateDetailsStats]: {
		handler: (params) => useDateDetailsStore().setRoomTypeDateDetails(params),
	},
	[WsServerMessageEvent.RoomTypeDateDetailsBookingOccupancies]: {
		handler: (params) => useDateDetailsStore().setRoomTypeOccupancies(params),
	},
	[WsServerMessageEvent.GetRoomTypeSoldNightsRange]: {
		handler: (params) => useAccommodationsStore().setRoomTypeSoldNightsRange(params),
	},
	[WsServerMessageEvent.AccommodationDateDetailsBookingOccupancies]: {
		handler: (params) => useDateDetailsStore().setAccommodationOccupancies(params),
	},
	[WsServerMessageEvent.WSError]: {
		handler: (params) => {
			switch (params.targetEvent) {
				case WsServerMessageEvent.GetAccommodationBasePricesPredictionPreview:
					useBasePricesStore().setIsLoadingRegularPreview(false)
					useBasePricesStore().setIsLoadingTemporaryPreview(false)
					break
				default:
					break
			}
			console.warn(params)
		},
	},
	[WsServerMessageEvent.GetBillingProfiles]: {
		handler: (params) => {
			useBillingStore().setBillingProfiles(params)
		},
		loadingId: LoadingIds.GET_BILLING_PROFILES,
	},
	[WsServerMessageEvent.UpdateBillingInfo]: {
		handler: (params) => {
			useBillingStore().setBillingInfo(params)
		},
		loadingId: LoadingIds.GET_INVOICES,
	},
	[WsServerMessageEvent.GetInvoices]: {
		handler: (params) => {
			useBillingStore().setInvoices(params)
		},
	},
	[WsServerMessageEvent.GetAccommodationStrategy]: {
		handler: (params) => {
			useStrategiesStore().setStrategy(params)
		},
	},
	[WsServerMessageEvent.RoomTypesPricesPrediction]: {
		handler: (params) => {
			useStrategiesStore().setStrategiesPricePredictions(params)
		},
	},
	[WsServerMessageEvent.GetAccommodationSoldNightsRange]: {
		handler: (params) => {
			useAccommodationsStore().setSoldNightsRange(params)
		},
	},
	[WsServerMessageEvent.GetEventDetails]: {
		handler: (params) => {
			useEventsStore().setEventDetails(params)
		},
	},
	[WsServerMessageEvent.CreateEvent]: {
		handler: (params) => {
			useEventsStore().confirmSavedEvent(params)
		},
	},
	[WsServerMessageEvent.ToggleEventVisibility]: {
		handler: (params) => {
			useEventsStore().toggleCurrentEventVisibilityConfirmed(params)
		},
	},
	[WsServerMessageEvent.RemoveEvent]: {
		handler: (params) => {
			useEventsStore().removeEventConfirmed(params)
		},
	},
	[WsServerMessageEvent.UpdateEvent]: {
		handler: (params) => {
			useEventsStore().confirmUpdatedEvent(params)
		},
	},
	[WsServerMessageEvent.GetEventImpactPreview]: {
		handler: (params) => {
			useEventsStore().previewReceived(params)
		},
	},
	[WsServerMessageEvent.GetAccommodationsMarketDemand]: {
		handler: (params) => {
			useMarketDemandStore().setDemands(params)
		},
	},
	[WsServerMessageEvent.GetUserSettings]: {
		handler: (params) => {
			useUserSettingsStore().setUserSettings(params)
		},
	},
	[WsServerMessageEvent.UpdateUserSettings]: {
		handler: (params) => {
			useUserSettingsStore().setUserSettings(params.userSettings)
		},
	},
	[WsServerMessageEvent.GetAccommodationBasePrices]: {
		handler: (params) => {
			useBasePricesStore().setBasePrices(params)
			useStrategiesStore().setStartingPrices(params)
		},
		loadingId: LoadingIds.ACCOMMODATIONS_BASE_PRICES,
	},
	[WsServerMessageEvent.GetAccommodationGlobalModifiers]: {
		handler: (params) => {
			useSettingsStore().setGlobalMinMaxPrices(params)
		},
	},
	[WsServerMessageEvent.UpdateAccommodationBasePrices]: {
		handler: () => usePricesStore().requestPrices(),
	},
	[WsServerMessageEvent.GetAccommodationBasePricesPredictionPreview]: {
		handler: (params) => {
			useBasePricesStore().setBasePricesPreview(params)
		},
		loadingId: LoadingIds.GET_PREDICTION_PREVIEW,
	},
	[WsServerMessageEvent.GetRoomTypesPricesDetails]: {
		handler: (params) => {
			usePricesStore().setPriceDetails(params)
		},
	},
	[WsServerMessageEvent.GetOnboardingProfileLastUpdate]: {
		handler: (params) => {
			useOnboardingStore().setLastUpdate(params)
		},
	},
	[WsServerMessageEvent.WSPricesPredictionPreviewError]: {
		handler: (params) => {
			useBannerStore().addBanner({
				canClose: true,
				closeCallback: handleDerivedStrategyBanner,
				cardType: CardTypes.ERROR,
				title: TranslationKeys.FAILED_PREVIEW,
				message: TranslationKeys.FAILED_PREVIEW_TEXT,
			})
		},
	},
	[WsServerMessageEvent.GetAdvancedExpertUserParamsMapping]: {
		handler: (params) => {
			useStrategiesStore().setAdvancedModeParamsMapping(params)
		},
		loadingId: LoadingIds.STRATEGY_ADVANCED_EXPERT_USER_PARAMS_MAPPING,
	},

	// AutoOnboarding
	[WsServerMessageEvent.GetAvailablePartners]: {
		handler: (params, connectionString) => {
			useAutoOnboardingStore().setAvailablePartners(params)
		},
	},
	[WsServerMessageEvent.GetPartnerAccessInfoSchema]: {
		handler: (params, connectionString) => {
			useAutoOnboardingStore().setPartnerSchema(params)
		},
		loadingId: LoadingIds.AUTO_ONBOARDING_PARTNER_SCHEMA,
	},
	[WsServerMessageEvent.SetOnboardingProgress]: {
		handler: (params) => {
			useAutoOnboardingStore().setProgressFromResponse(params)
		},
	},
	[WsServerMessageEvent.GetOnboardingProgress]: {
		handler: (params) => {
			useAutoOnboardingStore().setProgressFromResponse(params)
		},
		loadingId: LoadingIds.AUTO_ONBOARDING_GET_PROGRESS,
	},

	[WsServerMessageEvent.WSTokenExpiredErrorOnConnection]: {
		handler: () => {
			utilWebSocket.invalidateToken()
			utilNetwork.handleExpiredToken()
		},
	},
	[WsServerMessageEvent.WSTokenExpiredErrorOnMessage]: {
		handler: async (params) => {
			utilWebSocket.invalidateToken(undefined, { event: params.targetEvent, data: params.requestData })
			utilNetwork.handleExpiredToken()
		},
	},
	[WsServerMessageEvent.GetUserPreferences]: {
		handler: (params) => {
			useUserPreferencesStore().updateUserPreferences(params)

			useAccommodationsStore().setAccommodationsToShow(
				(params as GetUserPreferencesResponse).preferences.focusMode?.active || []
			)
		},
	},
	[WsServerMessageEvent.UpdateOrphanNightsSettings]: {
		handler: (params) => {
			useNotificationsStore().addNotification(<IMessageCardData>{
				title: useLocale().translate(TranslationKeys.UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_SUCCESS_TITLE),
				message: useLocale().translate(TranslationKeys.UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_SUCCESS_DESCRIPTION),
				canClose: true,
				cardType: CardTypes.SUCCESS,
				testId: TestIds.AccommodationGapSavedPopup,
			})
			useOrphanNightsStore().setSettingsAfterUpdate(params)
		},
	},
	[WsServerMessageEvent.GetOrphanNightsFixedDates]: {
		handler: (params) => useOrphanNightsStore().setFixedDates(params),
	},
	[WsServerMessageEvent.GetAccommodationsPricesSent]: {
		handler: (params) => usePricesStore().setPricesSentStatus(params),
	},
}
