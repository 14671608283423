export const enum UserProfileTrackingProperties {
  AccommodationsIds = 'accommodations_ids',
  AccommodationsNames = 'accommodations_names',
  MarketOccupancyAvailable = 'market_occupancy_available',
  MarketOccupancyStatus = 'market_occupancy_status',
  MarketCorrectionAvailable = 'market_correction_available',
  MarketCorrectionEnabled = 'market_correction_enabled',

  // Orphan Nights
  GapFillingAvailablePropertiesCount = 'Gap filling available properties count',
  GapFillingEnabledPropertiesCount = 'Gap filling enabled properties count',
}
